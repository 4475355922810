import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import sharedScripts from "./dependencies/sharedScripts";

import mitt from 'mitt';

const eventBus = mitt();

// globally set dateOptions
const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true
}

// Global app status object
const globalStatus = {
    code: null,
    message: null,
    ok: true,
    userMustDismiss: false,
    created: new Date().getTime(),
    createdTimeString: "",
    createdDateTimeString: "",
    clientSessonTimeout: false,
    forceLogout: false,
    accountLocked: false
}

// sessionStorage persistence
const storageKey = "playerApp";
const session = {
    get() {
        const storage = JSON.parse(sessionStorage.getItem(storageKey) || "{}");
        return storage;
    },
    add(key, value) {
        let storage = JSON.parse(sessionStorage.getItem(storageKey) || "{}");
        storage[key] = value;
        sessionStorage.setItem(storageKey, JSON.stringify(storage));
    },
    save(storage) {
        sessionStorage.setItem(storageKey, JSON.stringify(storage));
    },
    deleteAll() {
        sessionStorage.removeItem(storageKey);
    }
}

const authenticationCheck = async (vueInstance) => {
    // checkAndRefreshSession() returns either an updated state or a status object with errors
    let authenticationCheck = await sharedScripts.checkAndRefreshSession(vueInstance, vueInstance.playerState);
    if (authenticationCheck.hasOwnProperty("ok") && !authenticationCheck.ok) {
        eventBus.emit("updateStatus", authenticationCheck);
        if (authenticationCheck.forceLogout) eventBus.emit("forceLogout");
        return false;
    } else {
        eventBus.emit("updateAdminState", authenticationCheck);
        return authenticationCheck;
    }
}

const getUserPermissions = async (userId, token) => {
    let baseUrl = process.env.VUE_APP_RABBITSFOOT_HOST_URL;
    let headerObj = new Headers();
    headerObj.append("Content-Type", "application/json; charset=utf-8");
    headerObj.append("Authorization", `Bearer ${token}`);
    let requestUrl = new URL(`/api/v1/user/${userId}/permissions`, baseUrl);
    let request = new Request(requestUrl.toString(), {
        method: 'GET',
        headers: headerObj,
    })
    try {
        const response = await fetch(request);
        let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

        if (fetchStatus && !fetchStatus.ok || fetchStatus && response === "Bad OldAccessToken") {
            console.error(response);
            return fetchStatus;
        }

        var dataObj = await response.json();
        return dataObj;

    } catch (e) {
        console.error(e);
    }
}

// call to correct floating point imprecision
const roundFloat = (float) => {
    return Math.round((float + Number.EPSILON) * 100) / 100;
};

const specialCharacters = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";

const characterClassCheck = (pwString, minCharClasses) => {
    let matchedSpecial = pwString.split("").filter((char) => specialCharacters.includes(char));
    let matchedUpper = pwString.split("").filter((char) => char === char.toUpperCase() && char === char.match(/\p{L}/gu)?.toString());
    let matchedLower = pwString.split("").filter((char) => char === char.toLowerCase() && char === char.match(/\p{L}/gu)?.toString());
    let matchedNumeric = pwString.match(/\d/g);
    let hasSpecial = matchedSpecial?.length > 0 ? 1 : 0;
    let hasUpper = matchedUpper?.length > 0 ? 1 : 0;
    let hasLower = matchedLower?.length > 0 ? 1 : 0;
    let hasDigit = matchedNumeric?.length > 0 ? 1 : 0;

    let characterClassesValid = hasSpecial + hasUpper + hasLower + hasDigit >= minCharClasses;
    let characterClassCheck = {
        matchedSpecialChars: matchedSpecial,
        matchedUpperChars: matchedUpper,
        matchedLowerChars: matchedLower,
        matchedNumericChars: matchedNumeric,
        characterClassesValid: characterClassesValid,
    };
    return characterClassCheck;
};

const scorePassword = (pass) => {
    // Stackoverflow: https://stackoverflow.com/questions/948172/password-strength-meter
    // User: https://stackoverflow.com/users/46617/tm-lv
    var score = 0;
    if (!pass) return score;

    // award every unique letter until 5 repetitions
    var letters = new Object();
    for (var i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    };

    let variationCount = 0;
    for (var check in variations) {
        variationCount += variations[check] == true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return parseInt(score);
};

const getDocumentKeys = (documents) => {
    let documentKeys = [];
    for (const doc in documents) {
        documentKeys.push(documents[doc].key);
    }
    // remove duplicate keys
    let uniqueKeys = [...new Set(documentKeys)];
    return uniqueKeys;
}

const listDocuments = async (vueInstance, key = null, currentLimit = 20) => {

    // get one more item than requested to see if a second page exists
    // let currentLimit = initialLimit++;

    // Check if session needs to be refreshed
    let success = await authenticationCheck(vueInstance);
    if (success.hasOwnProperty("ok") && !success.ok) {
        return false;
    }

    let requestUrl = new URL("/api/v1/document", vueInstance.rabbitsfootHostUrl);
    let headerObj = new Headers();
    headerObj.append("Content-Type", "application/json; charset=utf-8");

    let params = requestUrl.searchParams;

    // generally key is used to represent a MIME type but the server will accept an arbitrary string
    if (key) params.set("key", key);
    if (currentLimit) params.set("limit", currentLimit);

    requestUrl.search = params.toString();

    let request = new Request(requestUrl.toString(), {
        method: "GET",
        headers: headerObj,
    });

    try {
        const response = await fetch(request);

        let fetchStatus = sharedScripts.checkFetchErrors(response);

        if (fetchStatus && !fetchStatus.ok) {
            vueInstance.serverBusy = false;
            vueInstance.eventBus.emit("updateStatus", fetchStatus);
            if (fetchStatus.forceLogout) vueInstance.eventBus.emit("forceLogout");
            return;
        }

        let dataJson = await response.json();

        console.log(dataJson);

        // remove extra item if a next page exists
        // if (!dataJson.isLastPage) dataJson.pop();


        // let returnObject = {
        //     dataJson: dataJson,
        //     isLastPage: dataJson?.length <= initialLimit
        // }

        return dataJson;

    } catch (e) {
        console.error(e);
        return false;
    }
};

const listDocumentAcknowlegement = async (vueInstance, languageErrorStrings) => {

    // Check if session needs to be refreshed
    let success = await authenticationCheck(vueInstance);
    if (success.hasOwnProperty("ok") && !success.ok) {
        return false;
    }

    let requestUrl = new URL("/api/v1/document/acknowledgement", vueInstance.rabbitsfootHostUrl);
    let headerObj = new Headers();
    headerObj.append("Authorization", `Bearer ${vueInstance.playerState.accessToken}`);
    headerObj.append("Content-Type", "application/json; charset=utf-8");

    let request = new Request(requestUrl.toString(), {
        method: "GET",
        headers: headerObj,
    });

    try {
        const response = await fetch(request);

        let fetchStatus = sharedScripts.checkFetchErrors(response, languageErrorStrings);

        if (fetchStatus && !fetchStatus.ok) {
            vueInstance.serverBusy = false;
            vueInstance.eventBus.emit("updateStatus", fetchStatus);
            if (fetchStatus.forceLogout) vueInstance.eventBus.emit("forceLogout");
            return;
        }

        let dataJson = await response.json();

        return dataJson;

    } catch (e) {
        console.error(e);
        return false;
    }
};

const compareDocumentVersions = (documentArray = []) => {
    // This is to compare acknowledgement version numbers with available documents
    // Selects for latest documents available
    // We are relying on an assumption that the sysAdmin is using a sequential numbering system
    // Preferrably date as yyyyMMdd but any sequential numbering system should work

    const latestVersion = documentArray.reduce((maxVer, doc) => Math.max(maxVer, doc.version), 0);
    let documentJSON = documentArray.filter(doc => doc.version === latestVersion)[0];
    return documentJSON;
};

const checkIsNewerDocAvailable = (documentArray = [], acknowledegedDoc) => {
    // Returns true if documents avaiable on server contains one with a higher version number
    let newestDocReferenced = compareDocumentVersions(documentArray);
    return newestDocReferenced.version > acknowledegedDoc.version;
};


const removeScriptTag = (content) => {
    // Remove script tags to prevent XSS vulnerabiliy
    return content.replace(/<script[^>]*>([\s\S]*?)<\/script>/gi, '');
};

const camelToTitleCase = (text) => {
    let result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

const app = createApp(App).use(store).use(router);

app.config.globalProperties.eventBus = eventBus;
app.config.globalProperties.dateOptions = dateOptions;
app.config.globalProperties.getUserPermissions = getUserPermissions;
app.config.globalProperties.globalStatus = globalStatus;
app.config.globalProperties.session = session;
app.config.globalProperties.authenticationCheck = authenticationCheck;
app.config.globalProperties.camelToTitleCase = camelToTitleCase;
app.config.globalProperties.roundFloat = roundFloat;
app.config.globalProperties.specialCharacters = specialCharacters;
app.config.globalProperties.characterClassCheck = characterClassCheck;
app.config.globalProperties.scorePassword = scorePassword;
app.config.globalProperties.rabbitsfootHostUrl = process.env.VUE_APP_RABBITSFOOT_HOST_URL;
app.config.globalProperties.devMode = process.env.VUE_APP_DEV_MODE === "true";
app.config.globalProperties.localeString = process.env.VUE_APP_LOCALE_STRING;
app.config.globalProperties.currency = process.env.VUE_APP_CURRENCY;
app.config.globalProperties.operatorAppsVersion = process.env.OPERATOR_APPS_VERSION;
app.config.globalProperties.phoneNumberLength = process.env.VUE_APP_PHONE_LENGTH;
app.config.globalProperties.casinoGroupName = process.env.VUE_APP_CASINO_GROUP_NAME;
app.config.globalProperties.countryCode = process.env.VUE_APP_COUNTRY_CODE;
app.config.globalProperties.getDocumentKeys = getDocumentKeys;
app.config.globalProperties.listDocuments = listDocuments;
app.config.globalProperties.listDocumentAcknowlegement = listDocumentAcknowlegement;
app.config.globalProperties.removeScriptTag = removeScriptTag;
app.config.globalProperties.compareDocumentVersions = compareDocumentVersions;
app.config.globalProperties.checkIsNewerDocAvailable = checkIsNewerDocAvailable;

app.mount('#app');
