<template>
	<div id="document-display">
		<div v-if="serverBusy && !documentNotFound" class="loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<div v-if="selectedDocumentString?.length > 0">
			<h2 v-if="!documentNotFound">Please review and accept document{{ newDocuments.length > 1 ? "s" : "" }} to continue</h2>
			<!-- <div class="button-container" v-if="newDocuments.length > 1">
				<button class="btn" v-for="(item, index) in newDocuments" :key="index" @click="selectDisplayDocument(item)">
					{{ item?.key }}
				</button>
			</div> -->
			<h1 class="capitalize">{{ selectedDocument.key }}</h1>
			<div v-if="selectedDocument.format.includes('html')" class="document-display">
				<button class="btn accept" @click="acceptDocument(selectedDocument)">{{ documentNotFound ? "Close" : "Accept and continue" }}</button>
				<p v-html="selectedDocumentString"></p>
			</div>
			<div v-else class="document-display">
				<button class="btn accept" @click="acceptDocument(selectedDocument)">{{ documentNotFound ? "Close" : "Accept and continue" }}</button>
				<p>{{ selectedDocumentString }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "DocumentDisplay",
	props: {
		playerState: Object,
		isMobile: Boolean,
		languageStrings: Object,
		languageErrorStrings: Object,
		newDocuments: Object,
		documentList: Array,
	},
	components: {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			selectedDocument: {},
			selectedDocumentString: "",
			serverBusy: false,
			busyText: "",
			docIndex: 0,
			documentNotFound: false,
		};
	},
	// watch: {
	// 	selectedDocumentString() {
	// 		if (this.newDocuments.length === 0) this.eventBus.emit("listDocumentAcknowlegement");
	// 		if (this.newDocuments.length > this.docIndex) this.selectDisplayDocument(this.newDocuments[this.docIndex]);
	// 	},
	// },
	created() {},
	mounted() {
		if (this.newDocuments.length === 0) this.eventBus.emit("listDocumentAcknowlegement");
		if (this.newDocuments?.length > this.docIndex) this.selectDisplayDocument(this.newDocuments[this.docIndex]);
	},
	methods: {
		async acceptDocument(doc) {
			if (this.documentNotFound) {
				this.eventBus.emit("forceLogout");
				return false;
			}

			this.serverBusy = true;
			this.busyText = "Saving document acknowlegment";

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				updates: [
					{
						key: doc.key,
						version: doc.version,
					},
				],
			};

			let requestUrl = new URL("/api/v1/document/acknowledgement", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					this.serverBusy = false;
					this.busyText = "";
					return;
				}

				let dataJson = await response.json();

				this.docIndex++;

				this.selectedDocument = {};
				this.selectedDocumentString = "";
				this.eventBus.emit("listDocumentAcknowlegement");
				if (this.newDocuments?.length > this.docIndex) this.selectDisplayDocument(this.newDocuments[this.docIndex]);

				this.docIndex = 0;

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
			}
		},
		async selectDisplayDocument(doc) {
			this.selectedDocument = doc;
			this.serverBusy = true;
			this.busyText = "Loading document";

			try {
				// sysAdmin should be able to use relative or absolute urls
				let documentUrl = doc.url.indexOf("http") === -1 ? `./${doc.url}` : doc.url;
				let response = await fetch(documentUrl);

				let documentLoaded = await response.text();

				documentLoaded.replace("\n", "<br \/>");
				documentLoaded.replace("\r", "<br \/>");

				if (documentLoaded.includes("indexLoaded-DocumentNotFound")) {
					this.documentNotFound = true;
					this.status.ok = false;
					this.status.message = "Required document not found. Please contact Administrator or Help desk.";
					this.eventBus.emit("updateStatus", this.status);
					this.selectedDocumentString = this.status.message;
					this.eventBus.emit("forceLogout");
					return false;
				}
				let contentString = documentLoaded;
				this.selectedDocumentString = this.removeScriptTag(contentString);

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = `Required document not found. Please contact Administrator or Help desk. ${e}.`;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("forceLogout");
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading {
	text-align: center;
}

.loading,
.loading-message {
	margin-top: 15px;
}

#document-display {
	position: fixed;
	display: grid;
	align-content: flex-start;
	/* align-items: flex-start; */
	justify-content: center;
	padding: 30px 15px;
	margin: 0 auto;
	top: 98px;
	right: 0;
	bottom: 0%;
	left: 0;
	width: 90%;
	/* height: 100%; */
	height: calc(100% - 215px);
	overflow: hidden auto;
	background-color: rgb(0 0 0/90%);
	backdrop-filter: blur(8px);
	z-index: 9999;
}

.document-display {
	width: 90%;
	margin: auto;
}

.button-container {
	display: flex;
	justify-content: center;
}

.btn {
	margin: 5px 15px;
}

.accept {
	display: block;
	margin: 5px auto;
}

.capitalize {
	text-transform: capitalize;
}

@media (min-width: 768px) {
	#document-display {
		width: 100%;
		padding: 30px;
	}
}
</style>
