import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: `Home`
    }
  },
  {
    path: "/registerNewUser",
    name: "RegisterNewUser",
    component: () =>
      import(/* webpackChunkName: "registerNewUser" */ "../views/RegisterNewUser.vue"),
    meta: {
      title: 'Register New User'
    }
  },
  {
    path: "/extendedRegisterView",
    name: "ExtendedRegisterView",
    component: () =>
      import(/* webpackChunkName: "extendedRegisterView" */ "../views/ExtendedRegisterView.vue"),
    meta: {
      title: 'Register New User'
    }
  },
  {
    path: "/webPayCashIn",
    name: "WebPayCashIn",
    component: () =>
      import(/* webpackChunkName: "webPayCashIn" */ "../views/WebPayCashIn.vue"),
    meta: {
      title: 'Buy-In'
    }
  },
  {
    path: "/webPayCashOut",
    name: "WebPayCashOut",
    component: () =>
      import(/* webpackChunkName: "webPayCashOut" */ "../views/WebPayCashOut.vue"),
    meta: {
      title: 'Web Pay Cash-Out'
    }
  },
  {
    path: "/webPayTransactions",
    name: "WebPayTransactions",
    component: () =>
      import(/* webpackChunkName: "webPayCashOut" */ "../views/WebPayTransactions.vue"),
    meta: {
      title: 'WebPay Transactions'
    }
  },

  {
    path: "/userProfile",
    name: "UserProfile",
    component: () =>
      import(/* webpackChunkName: "userProfile" */ "../views/UserProfile.vue"),
    meta: {
      title: 'User Profile'
    }
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: () =>
      import(/* webpackChunkName: "changePassword" */ "../views/ChangePassword.vue"),
    meta: {
      title: 'Change Password'
    }
  },
  {
    path: "/verifyEmail",
    name: "VerifyEmail",
    component: () =>
      import(/* webpackChunkName: "verifyEmail" */ "../views/VerifyEmail.vue"),
    meta: {
      title: 'Verify Email'
    }
  },
  {
    path: "/systemInfo",
    name: "SystemInfo",
    component: () =>
      import(/* webpackChunkName: "systemInfo" */ "../views/SystemInfo.vue"),
    meta: {
      title: 'System Info | Development Only'
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: 'About Player App | Documentation'
    }
  },
  // IdentificationStatus
  {
    path: "/identificationStatus",
    name: "IdentificationStatus",
    // route level code-splitting
    // this generates a separate chunk (identificationStatus.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "identificationStatus" */ "../views/IdentificationStatus.vue"),
    meta: {
      title: 'Identification Status'
    }
  },

];


// Use ./ as the routing base if we're in dev mode, or if the env variables
// say this app has a subdomain of its own.  Otherwise assume it's running
// in the ./player/ directory.
const useSubdirRouting = (process.env.VUE_APP_WEBCONFIG_TYPE !== 'subdomain') &&
  (process.env.NODE_ENV !== 'development');

let historyMode = null;

if (useSubdirRouting) {
  historyMode = createWebHashHistory('./player/');
} else {
  historyMode = createWebHistory('./');
};

const router = createRouter({
  history: historyMode,
  routes,
});

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that was defined above.
  let title = to.meta.title;
  // If the route has a title, use it as the page title. If not, we'll just title it Player App.
  document.title = title ? title : "Player App";
  // Continue resolving the route
  next();
});

export default router;
