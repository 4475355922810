<template>
	<div>
		<button
			v-if="
				(systemSettings?.features?.allowBarcodeAccountAccess || systemSettings?.features?.allowVerbalCodeAccountAccess) &&
				playerState.isUserVerified &&
				systemSettings?.features?.allowMultiCasinoAccountAccessCodes
			"
			@click="requestOpenQRCodeModal()"
			class="btn"
		>
			{{ languageStrings.getQRcode }}
		</button>

		<table class="balances" v-for="(item, index) in casinosForCodeGeneration" :key="index">
			<tr>
				<th>Casino</th>
				<td>{{ item.name }}</td>
			</tr>
			<tr>
				<th>Regular</th>
				<td>{{ item.currencyTool.formatFromAU(item.balance.regularAU, item.currencyTool.displayType.full) }}</td>
			</tr>
			<tr>
				<th>Promo</th>
				<td>{{ item.currencyTool.formatFromAU(item.balance.promoAU, item.currencyTool.displayType.full) }}</td>
			</tr>
			<tr>
				<th>{{ languageStrings.lcokedFunds }}</th>
				<td>{{ item.currencyTool.formatFromAU(item.lockedFundsAU, item.currencyTool.displayType.full) }}</td>
			</tr>
			<tr>
				<th>{{ languageStrings.lockFundsCount }}</th>
				<td>{{ item.lockedClaimsCount }}</td>
			</tr>
			<tr
				v-if="
					(systemSettings?.features?.allowBarcodeAccountAccess || systemSettings?.features?.allowVerbalCodeAccountAccess) &&
					playerState.isUserVerified &&
					!systemSettings?.features?.allowMultiCasinoAccountAccessCodes &&
					countryCode !== 'MX'
				"
			>
				<th>{{ languageStrings.codeAvailable }}</th>
				<button @click="requestOpenQRCodeModal(item.id)" class="btn">{{ languageStrings.getQRcode }}</button>
			</tr>
		</table>
	</div>
</template>

<script>
export default {
	name: "HomeTableMobile",
	props: {
		systemSettings: Object,
		casinosForCodeGeneration: Array,
		playerState: Object,
		languageStrings: Object,
	},
	methods: {
		requestOpenQRCodeModal(casinoId) {
			this.eventBus.emit("requestQRModal", casinoId);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.balances {
	position: relative;
	width: 90%;
	margin: 0 auto 30px;
	border-collapse: collapse;
	text-align: center;
	background-color: #d7d7d7;
	box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%);
	box-sizing: border-box;
	border: 1px #000 solid;
}

.balances th {
	background-color: black;
	color: white;
	padding: 8px 2px;
	width: 50%;
}

.balances tr {
	transition: background-color 0.3s;
}

.balances tr:nth-child(2n):not(.open):hover {
	background-color: #fff;
	color: #000;
}

.balances tr:nth-child(2n):not(.open) {
	background-color: #bbb;
}

button.btn {
	display: block;
}
</style>
